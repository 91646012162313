html {
  box-sizing: border-box;
}

@font-face {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  src: local('Anton'), url(https://themes.googleusercontent.com/static/fonts/anton/v4/5t8vP9Hx2udzw372glmOxA.ttf) format('truetype');
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #F2F2F2;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
a {
  text-decoration: none;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
/* [type=button]{
  -webkit-appearance: none !important;
} */
